.container {
  height: 100%;
  position: fixed;
  max-width: 100%;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 990;
  background-color: white;
  box-sizing: border-box;
  overflow-y: auto;
}

.titleContainer {
  flex-direction: row-reverse;
  min-height: 7.1%;
  height: 7.1%;
  padding: 0 1rem;
}

.textBox {
  border-top: 1px solid black;
  padding: 1rem;
}
.switcherContainer {
  border-bottom: 1px solid black;
}
.switcherButton {
  margin-right: 2rem;
  cursor: pointer;
}
.onSelected {
  text-decoration: underline;
}
.split {
  border-top: 5px solid black;
  line-height: 1.45;
  display: flex;
}
@media (max-width: 1025px) {
  .textBox {
    /* font-size: 0.9rem !important; */
  }
  .split {
    padding: 1rem 1rem 0.5rem 1rem;
    display: block;
  }
}
