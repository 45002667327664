.singleContainer {
  height: 77%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.singleImg {
    display: block;
}

.modalContent {
   -webkit-animation-name: zoom;
   -webkit-animation-duration: 0.6s;
   animation-name: zoom;
   animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)}
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}


@media (max-width: 767px) {

  .singleContainer {
    height: auto;
    min-width: 100%;
}

  .singleImg {
      max-width: 100%;
      max-height: 100%;
  }
}


