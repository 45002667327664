.emailBoxContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem 0 2rem 1rem;
  border-bottom: 1px solid black;
  box-sizing: border-box;
}

.emailBox {
  width: 60%;
  font-size: inherit;
  margin-top: 0.5rem;
}

.emailBox:focus {
  outline: black;
}

@media (max-width: 1024px) {
  .emailBox {
    width: 90%;
  }
}
