.imgArea {
  height: 46.2%;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
}
.inputContainer {
  width: fit-content;
  margin: 1rem auto;
  position: relative;
  font-size: inherit;
}
.input {
  margin-top: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  height: 2.2rem;
  font-size: inherit;
}
.input:focus {
  outline: none;
}
.displayUnit {
  font-size: inherit;
  position: absolute;
  bottom: 0.2rem;
  margin-left: 0.35rem;
  letter-spacing: 1px;
}
.switcherContainer {
  border-bottom: 1px solid black;
}
.switcherButton {
  margin-right: 2rem;
  cursor: pointer;
}
.onSelected {
  text-decoration: underline;
}
.ftDiv {
  align-items: center;
  justify-content: space-around;
  display: flex;
  width: 25%;
  margin: auto;
  padding-bottom: 1rem;
}
.ftSubDiv {
  position: relative;
}
.ftInput {
  margin-top: 0.3rem;
  box-sizing: border-box;
  width: 5rem;
  height: 2.2rem;
  font-size: inherit;
}
.ftInput:focus {
  outline: none;
}
.cmDiv {
  position: relative;
}
.displayFt {
  font-size: inherit;
  position: absolute;
  bottom: 0.2rem;
  right: -1rem;
  letter-spacing: 1.5px;
}
@media (max-width: 1024px) {
  .imgArea {
    border-bottom: 1px solid black;
  }
  .ftDiv {
    width: 70%;
  }
  .inputContainer,
  .input,
  .ftInput {
    font-size: inherit;
  }
}
