.video-wrapper {
    position: relative;
    padding: 1.5rem 0;
    height: 56.25vw; /* (9 / 16) * 100vw */
    /* min-height: 200px; */
    max-height: 65vh;
}

.video-wrapper>div>video {
    outline: none;
}

@media only screen and (max-width: 1025px) {
    .video-wrapper {
        padding: 0;
    }
}