.logo {
  height: 100%;
}

.head {
  border-bottom: 1px solid black;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 7.1%;
  position: relative;
  justify-content: space-between;
}
.head > a {
  height: 82%;
  width: auto;
  margin-left: 1rem;
}

.description {
  margin-right: 1rem;
}

@media (max-width: 1025px) {
  .head > a {
    height: 33px;
  }
  .description {
    font-size: 0.8rem;
    margin-left: 1rem;
    line-height: 1rem;
  }
}
