.containerForm {
  height: 92.3%;
}

.fieldset {
  height: 100%;
}

.labelText {
  /* padding-left: 1rem; */
  width: 10%;
}

.inputText {
  border: 1px solid black;
  font-size: inherit;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
}

.hide {
  display: none;
}

.cardText {
  /* padding-left: 1rem; */
}

.btn {
  border: 1px solid black;
  font-size: inherit;
  background-color: white;
  padding: 5px;
  color: black;
  /* margin-left: 1rem; */
}

.btn:hover {
  cursor: pointer;
}
.selectorForPhone {
  border: 1px solid black;
  font-size: inherit;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  color: black;
  border-radius: 0;
}

@media only screen and (max-width: 75em) {
  .labelText {
    width: 10%;
  }
}

@media only screen and (max-width: 56.25em) {
  .labelText {
    width: 20%;
  }
}

@media only screen and (max-width: 1025px) {
  .labelText {
    width: 30%;
  }

  .inputText {
    font-size: inherit;
  }

  .atCenter {
    justify-content: center;
  }
  .atCenter > div {
    height: 90%;
    box-sizing: border-box;
    padding: 10px;
  }
  .btn {
    font-size: inherit;
  }
  .selectorForPhone {
    font-size: inherit;
    background-color: white;
  }

  .taxBoxForAll {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
