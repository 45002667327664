.arrowPrev,
.arrowNext {
  position: absolute;
  top: 0;
  z-index: 5;
  height: 100%;
  display: flex;
  align-items: center;
  width: 30%;
}

.arrowPrev {
  left: 1%;
  cursor: pointer;
}

.arrowNext {
  right: 1%;
  cursor: pointer;
  justify-content: space-between;
}