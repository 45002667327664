.span {
  margin-top: 0.5rem;
  font-size: inherit;
}

.here {
  padding-left: 0.3rem;
  font-size: inherit;
  color: black;
  font-weight: bold;
}

.textbox {
  border: 1.2px solid black;
  font-size: inherit;
  text-indent: 0.1rem;
  margin-bottom: 0.5rem;
  outline: none;
  width: 90%;
  font-family: inherit;
}

.textbox::-webkit-input-placeholder {
  color: black;
}
.line {
  padding: 1rem 1rem;
  max-height: unset;
  box-sizing: unset;
  height: unset;
}
.rightButton {
  color: black;
  cursor: pointer;
  font-size: 1.1rem;
  padding-right: 0.5rem;
  outline: none;
}

.pictureSliderContainer {
  height: 77%;
  min-height: 77%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.centerImg {
  display: block;
  max-width: 100%;
}
.pTag {
  display: none;
}
.hideOnMob {
  display: flex;
}
.text {
  /* border-bottom: 1px black solid; */
  border-top: 1px black solid;
}
@media (max-width: 1025px) {
  .textbox {
    text-indent: 0.2rem;
    font-family: inherit;
  }
  .pTag {
    padding: 1rem 1.5rem;
    display: block;
    border-bottom: 1px solid black;
    font-size: 1.1rem !important;
    line-height: 1.45;
  }
  .hideOnMob {
    display: none;
  }
}
