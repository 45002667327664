.container {
  box-sizing: border-box;
  border-bottom: 1px solid black;
  padding: 1rem;
}
.title {
  font-size: inherit;
  padding-bottom: 1rem;
}
.description {
  font-size: inherit;
  padding-bottom: 1rem;
  line-height: 1.45;
}

.imgBox {
  height: calc(100vh * 0.462);
  max-width: 100%;
}

@media (max-width: 1025px) {
}
