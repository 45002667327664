.inputBox {
  border: 1px solid black;
  font-size: inherit;
  box-sizing: border-box;
  width: 50rem;
}
.btn {
  border: 1px solid black;
  font-size: inherit;
  background-color: white;
  padding: 5px;
  margin-left: 1rem;
  margin-top: 1rem;
}

.btn:hover {
  cursor: pointer;
}

@media (max-width: 1025px) {
  .inputBox {
    width: 100%;
  }
}
