.button {
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  padding: unset;
  color: black;
  display: flex;
  font-size: inherit;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
