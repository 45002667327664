.buttonsContainer {
  display: flex;
  justify-content: space-between;
}
.button {
  background-color: black;
  color: white;
  font-size: inherit;
  font-weight: bold;
  padding: 0.25rem 1.4rem;
  border: none;
  cursor: pointer;
  width: 25%;
}
.button:focus {
  outline: none;
}
@media (max-width: 1024px) {
  .button {
    width: unset;
    padding: 0.25rem 1.1rem;
  }
}
