/* common css for links and navlink -please make className same as below*/
.links {
  display: flex;
  flex-direction: column;
  height: 92.9%;
}

.navLink {
  text-decoration: none;
  color: black;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  line-height: 1.45;
}

/* follow us page styling */
.followUs {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: black;
  padding-left: 1rem;
  box-sizing: border-box;
}

/* text/body styling */
.text {
  line-height: 1.45;
  padding: 1rem;
}


@media (max-width: 1025px) {
}
