/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  /* padding: 12px; */
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid black;
  /* padding-bottom: 5rem; */
  min-height: 30px;
  max-height: 30px;
  box-sizing: border-box;
  /* padding-bottom: 0; */
  padding: 5px;

  /* margin-top: -30px; */
  /* margin-bottom: 0.5rem; */
  /* border-radius: 4px; */
  /* box-shadow: 0 1px 3px 0 #e6ebf1; */
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  font-family: inherit;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

@media only screen and (max-width: 1025px) {
  .StripeElement  {
    /* width: 100%; */
   /* background-color: green; */
  min-height: 25px;
  max-height: 25px;
  /* margin-bottom: 5rem; */
  /* padding: -20px; */
  /* margin-top: 20px; */
  /* padding-top: 2rem;
  /* padding-left: 0; */
  /* padding-left: 0 !important;
  padding-bottom: 10px !important; */
  padding-left: 2px !important;
  padding-right: 5px !important;
  padding-top: 2px !important;
  padding-bottom: 8px !important;
 
 /* padding-top: 2px; */



  /* margin-bottom: 30px; */
  font-size: 5px;

  }

}
