.paginate-container {
  width: 100%;
}

.paginate-container > ul {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.paginate-container > ul > li {
  cursor: pointer;
  border: 1px solid black;
  padding: 0.5rem 1rem;
}

.paginate-container > ul > li > a {
  outline: none;
}

.products-title {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
}

.products-info {
  flex: 1;
  display: flex;
  justify-content: space-around;
}

.products-cmd {
  margin-right: 1rem;
}

.bolder-a {
  font-weight: bolder;
  cursor: default !important;
}

.disabled {
  color: grey;
  cursor: default !important;
}

.between {
  justify-content: space-between;
}
