.container {
  height: 23.1%;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
}
.additionalLine {
  border-bottom: 1px solid black;
}
.imgBox {
  height: 100%;
  margin-right: 1.5rem;
}

.contentBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wordsLine {
  font-size: inherit;
}
.wordsLine:last-child {
  display: flex;
  align-items: center;
}
.sub {
  vertical-align: sub;
  color: black;
  font-size: inherit;
  margin-left: 5px;
  letter-spacing: 1px;
}
.text {
  padding: 0 1rem 1rem 1rem;
}

@media (max-width: 1025px) {
  .container {
    height: 15.4%;
    padding: 0.5rem 1rem;
  }
  .imgBox {
    margin-right: 1rem;
  }
  .wordsLine:last-child {
    font-size: inherit;
  }
  .sub {
    vertical-align: unset;
    letter-spacing: 1px;
    font-weight: normal;
  }
  .text {
    font-size: inherit;
    padding-bottom: 0.5rem;
  }
}
