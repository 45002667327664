.bottomLine {
    box-sizing: border-box;
    border-bottom: 1px solid black;
    height: 7.7%;
    min-height: 7.7%;
    max-height: 7.7%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.topLine {
    box-sizing: border-box;
    border-top: 1px solid black;
    height: 7.7%;
    min-height: 7.7%;
    max-height: 7.7%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.emptyLine {
    height: 7.7%;
    min-height: 7.7%;
    max-height: 7.7%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
}