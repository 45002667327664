.image-container-for-three-options{
    height: 46.2% !important;
    box-sizing: border-box;
    border-bottom: 1px solid black;
}
.image-container-for-three-options>.slick-list,.slick-track,
.image-container-for-three-options>div>div>div,
.image-container-for-three-options>div>div>div>div{
    height: 100%;
}
.slick-slide .singleImg {
    width: unset !important;
    display: block !important;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
}

@media only screen and (max-width: 1025px) {
    .slick-slide .centerImg {
        width: 100%;
    }
    .slick-slide .singleImg {
        width: 100%;
    }
}