.centerText {
  padding-left: 1rem;
}

.navLink {
  text-decoration: none;
  color: black;
  padding-left: 1rem;
  box-sizing: border-box;
}
.sizeLink {
  text-decoration: none;
  color: black;
  box-sizing: border-box;
}
.basicLine {
  cursor: pointer;
}

.black {
  background-color: #000000;
  color: white;
}

.white {
  background-color: #ffffff;
}

.blue {
  background-color: #0073cf;
}

.yellow {
  background-color: #ffff00;
}

.green {
  background-color: #009600;
}

.grey {
  background-color: #808080;
}

.orange {
  background-color: #ff9400;
}

.purple {
  background-color: #bf00ff;
}

.red {
  background-color: #ff0000;
}

.brown {
  background-color: #7b3f00;
}

.pink {
  background-color: #ff69b4;
}
