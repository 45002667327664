.imgs-container {
    display: flex;
    flex-wrap: wrap;
}

.img-of-product {
    margin: 1rem 1.5rem;
    position: relative;
}

.img-delete-button {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    right: 0;
}

.img-delete-button:hover {
    cursor: pointer;
}