.container{
    height: 100%;
    position: fixed;
    max-width: 100%;
    width: 100%;
    right: 0;
    top: 0;
    z-index: 990;
    background-color: white;
    box-sizing: border-box;
    overflow-y: auto;
}
.titleContainer{
    flex-direction: row-reverse;
    justify-content: space-between;
    min-height: 7.1%;
    height: 7.1%;
}