:root {
  --mainWhite: #ffffff;
  --mainBlack: #000000;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
   
*/

/* html{
  scroll-behavior: smooth;
} */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

div {
  outline: none !important;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0 !important;
  border: 1px black solid;
  outline: none;
  font-family: inherit;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  height: 100vh;
  min-height: 100vh;
  width: 100%;
}

body {
  font-family: "Encode Sans Condensed", sans-serif;
  background: var(--mainWhite) !important;
  color: var(--mainBlack) !important;
  width: 100%;
  height: 100%;
  line-height: 1;
  text-transform: lowercase;
  font-size: 1.2rem;
}

button {
  font-family: "Encode Sans Condensed", sans-serif;
  text-transform: lowercase;
}

p {
  padding: 0.5rem 0.5rem;
}

textarea,
option,
select {
  font-size: inherit;
  font-family: inherit;
  border-radius: 0;
}

.navBar {
  flex-direction: column;
}
.text-area-admin {
  height: 15.4%;
  max-height: unset;
}
.text-area-admin > textarea {
  height: 82px;
}
.img-of-admin {
  margin: 1rem 1.5rem;
  position: relative;
}

.img-delete-button {
  height: 20px;
  width: 20px;
  position: absolute;
  color: white;
  top: 0;
  right: 0;
  background-color: black;
}

.img-delete-button:hover {
  cursor: pointer;
}

.swiper-product-details {
  width: 100%;
  height: 100%;
}
.swiper-modal-details {
  height: calc(100% - 44px);
  position: unset !important;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.swiper-product-details > .swiper-pagination {
  right: 1% !important;
  top: 1.6rem !important;
  width: fit-content !important;
  left: unset !important;
  height: fit-content !important;
  font-size: inherit;
}

.swiper-modal-details > .swiper-pagination {
  left: 1% !important;
  top: calc(22px - 0.75rem) !important;
  width: fit-content !important;
  height: fit-content !important;
  font-size: 1.2rem;
  color: white;
  font-weight: 600;
}

.swiper-button-next {
  color: black !important;
}
.swiper-button-prev {
  color: black !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 2rem !important;
}
@media only screen and (max-width: 1025px) {
  body {
    font-size: 1rem;
  }
  .swiper-button-next {
    display: none !important;
  }
  .swiper-button-prev {
    display: none !important;
  }
  .swiper-product-details > .swiper-pagination {
    top: 1rem !important;
    right: 2% !important;
    font-size: 1.2rem !important;
  }
}
